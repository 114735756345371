import AccessControl from '@/modules/SettingsModule/tabs/AccessControl';
import Security from '@/modules/SettingsModule/tabs/Security';
import Support from '@/modules/SettingsModule/tabs/Support';
import Bug from '@/modules/SettingsModule/tabs/Bug';
import Notifications from '@/modules/SettingsModule/tabs/Notifications';
import TaxesSettings from '@/modules/SettingsModule/tabs/Taxes/TaxesSettings';
import CompanyProfile from '@/modules/SettingsModule/tabs/CompanyProfile/CompanyProfile';
import Profile from '@/modules/SettingsModule/tabs/Profile';
import { Redirect } from 'react-router-dom';
import Permissions from '@/modules/SettingsModule/tabs/Permissions';
import SubscriptionSettings from '@/modules/SettingsModule/tabs/Subscription/SubscriptionSettings';

export const inventoryRoutesConfig = [
  {
    path: '/items/:id?',
    component: 'Item',
  },
  {
    path: '/itemsGroup',
    component: 'ItemsGroup',
  },
  {
    path: '/setCategories',
    component: 'SetCategories',
  },
  {
    path: '/inventoryAdjustment',
    component: 'InventoryAdjustment',
  },
  {
    path: '/warehouses',
    component: 'Warehouses',
  },
  {
    path: '/transferOrder/:id?',
    component: 'TransferOrder',
  },
];

export const salesRoutesConfig = [
  {
    path: '/salesTracking/:id?',
    component: 'SalesTracking',
  },
  {
    path: '/salesOrders/:id?',
    component: 'Orders',
  },
  {
    path: '/invoices/:id?',
    component: 'Invoices',
  },
  {
    path: '/deliveryNotes/:id?',
    component: 'Deliveries',
  },
  {
    path: '/clients/:id?',
    component: 'Clients',
  },
  {
    path: '/salesReturns/:id?',
    component: 'SalesReturns',
  },
  {
    path: '/quotes/:id?',
    component: 'Quotes',
  },
  {
    path: '/exitVouchers/:id?',
    component: 'Vouchers',
  },
];

export const purchaseRoutesConfig = [
  {
    path: '/supplier',
    component: 'Supplier',
  },
  {
    path: '/vendor',
    component: 'Purchases',
  },
];

export const settingsRoutesConfig = [
  {
    path: 'profile',
    element: <Profile />,
  },
  {
    path: 'company',
    element: <CompanyProfile />,
  },
  {
    path: 'taxes',
    element: <TaxesSettings />,
  },
  {
    path: 'subscription',
    element: <SubscriptionSettings />,
  },
  {
    path: 'notifications',
    element: <Notifications />,
  },
  {
    path: 'access-control',
    element: <AccessControl />,
  },
  {
    path: 'permissions',
    element: <Permissions />,
  },
  {
    path: 'security',
    element: <Security />,
  },
  {
    path: 'support',
    element: <Support />,
  },
  {
    path: 'bug',
    element: <Bug />,
  },
  {
    path: '',
    element: <Redirect to="/settings/profile" />,
  },
];
export const routesConfig = [
  {
    path: '/',
    component: 'Dashboard',
  },
  {
    path: '/documents',
    component: 'Document',
  },
  {
    path: '/customer',
    component: 'Customer',
  },
  // {
  //   path: '/invoice',
  //   component: 'Invoice',
  // },
  {
    path: '/currency',
    component: 'Currency',
  },
  {
    path: '/quote',
    component: 'Quote',
  },
  {
    path: '/payment/invoice',
    component: 'PaymentInvoice',
  },
  {
    path: '/employee',
    component: 'Employee',
  },
  {
    path: '/admin',
    component: 'Admin',
  },
  {
    path: '/payment/mode',
    component: 'PaymentMode',
  },
  {
    path: '/role',
    component: 'Role',
  },
  {
    path: '/oldBI',
    component: 'OldBI',
  },
];
