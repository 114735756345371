import * as actionTypes from './types';
import Cookies from 'js-cookie';

const INITIAL_STATE = {
  isLoggedIn: window.localStorage.getItem('isLoggedIn') === 'true',
  loading: false,
  subscriptionNotPaid: false,
  company: {},
  profile: {},
  role: {},
  position: [],
  industry: [],
  stockType: [],
};
const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.LOADING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FAILED_LOGIN_SUB:
      return { ...INITIAL_STATE, subscriptionNotPaid: true };
    case actionTypes.FAILED_REQUEST:
      return INITIAL_STATE;
    case actionTypes.LOGIN_SUCCESS:
      window.localStorage.setItem('isLoggedIn', 'true');
      if (!Cookies.get('token'))
        Cookies.set('token', action.payload.token, {
          domain: '.supplyzpro.com',
          sameSite: 'None',
          secure: true,
        });
      return { ...state, loading: false, isLoggedIn: true };
    case actionTypes.CURRENT_SUCCESS:
      window.localStorage.setItem('isLoggedIn', 'true');
      // window.localStorage.setItem('Language', action.payload?.profile?.language ?? 'en');
      return { ...state, ...action.payload, isLoggedIn: true, loading: false };
    case actionTypes.LOGOUT_SUCCESS:
      window.localStorage.removeItem('isLoggedIn');
      Cookies.remove('token');
      return { ...INITIAL_STATE, isLoggedIn: false };
    case actionTypes.SIGNUP_SUCCESS:
      return { ...state, loading: false };
    case actionTypes.UPDATE_PASSWORD_SUCCESS:
      return { ...state, loading: false };
    case actionTypes.UPDATE_PROFILE_SUCCESS:
      window.localStorage.setItem('Language', action.payload?.language);
      // setLanguage(action.payload.language);
      return { ...state, profile: action.payload, loading: false };
    case actionTypes.COMPLETE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPDATE_COMPANY_SUCCESS:
      return { ...state, company: action.payload, loading: false };
    case actionTypes.UPDATE_IMAGE_PROFILE_SUCCESS:
      const updatedProfile = { ...state.profile, image: action.payload.image };
      return { ...state, profile: updatedProfile, loading: false };
    case actionTypes.UPDATE_IMAGE_COMPANY_SUCCESS:
      const updatedCompany = { ...state.company, image: action.payload.image };
      return { ...state, company: updatedCompany, loading: false };
    case actionTypes.UPDATE_COMPANY:
      const { company } = state;
      company[action.payload.key] = action.payload.value;
      return { ...state, company };
    case actionTypes.GET_ADMIN_POSITION:
      return { ...state, position: action.payload, loading: false };
    case actionTypes.GET_COMPANY_INDUSTRY:
      return { ...state, industry: action.payload, loading: false };
    case actionTypes.GET_STOCK_TYPE:
      return { ...state, stockType: action.payload, loading: false };
    default:
      return state;
  }
};

export default authReducer;
