import * as actionTypes from './types';
import { request } from '@/request';
import { endpoints } from '@/request/api';
import { current } from '@/redux/auth/action/auth';

export const openModal = () => async (dispatch) => {
  dispatch({
    type: actionTypes.OPEN_MODAL,
  });
};

export const closeModal = () => async (dispatch) => {
  dispatch({
    type: actionTypes.CLOSE_MODAL,
  });
};
export const changeStatus = (id, isDraft) => async (dispatch) => {
  dispatch({
    type: actionTypes.LOADING_REQUEST,
    payload: { loading: true },
  });
  let data;
  if (isDraft) {
    data = await request.put({
      entity: 'quote',
      action: 'approve',
      id: id,
    });
  } else {
    data = await request.put({
      entity: 'quote',
      action: 'markDraft',
      id: id,
    });
  }

  if (data.success === true) {
    dispatch({
      type: actionTypes.CHANGE_STATUS_SUCCESS,
      payload: data.result,
    });
  } else {
    dispatch({
      type: actionTypes.FAILED_REQUEST,
      payload: data,
    });
  }
};

export const createEntity =
  (companyCounter = 1, entity, body) =>
  async (dispatch) => {
    dispatch({
      type: actionTypes.LOADING_REQUEST,
      payload: { loading: true },
    });
    const data = await request.create({
      entity: entity,
      jsonData: body,
    });

    if (data.success === true) {
      dispatch({
        type: actionTypes.CREATE_ENTITY_SUCCESS,
        payload: data.result,
      });
      if (entity === 'invoice' && data.result.invoice.invoiceCounter === companyCounter) {
        dispatch(current());
      }
      if (entity === 'order') {
        dispatch(getItemList(data.result.order.id));
      }
    } else {
      dispatch({
        type: actionTypes.FAILED_REQUEST,
        payload: data,
      });
    }
  };

export const createSaleReturn = (id) => async (dispatch) => {
  dispatch({
    type: actionTypes.LOADING_REQUEST,
    payload: { loading: true },
  });

  const data = await request.newPost({
    entity: 'Sales Returns',
    action: 'create',
    id: id,
  });
  if (data.success === true) {
    dispatch({
      type: actionTypes.CREATE_SALE_RETURN_SUCCESS,
      payload: data.result,
    });
    dispatch(getData({ page: 1 }));
  } else {
    dispatch({
      type: actionTypes.FAILED_REQUEST,
      payload: data,
    });
  }
};
export const updateSale = (id, body, entity) => async (dispatch) => {
  dispatch({
    type: actionTypes.LOADING_REQUEST,
    payload: { loading: true },
  });
  let data = {};
  data = await request.update({
    entity: entity.name,
    action: 'update',
    id: id,
    jsonData: body,
  });
  if (data.success === true) {
    dispatch({
      type: actionTypes.UPDATE_SUCCESS,
      payload: { data: data.result, entity: entity.label },
    });
  } else {
    dispatch({
      type: actionTypes.FAILED_REQUEST,
      payload: data,
    });
  }
};

const getItemList = (id) => async (dispatch) => {
  dispatch({
    type: actionTypes.LOADING_REQUEST,
    payload: { loading: true },
  });
  let data = {};
  data = await request.newRead({
    entity: 'order',
    action: 'priceDifference',
    id: id,
  });
  if (data.success === true) {
    dispatch({
      type: actionTypes.ITEM_LIST_SUCCESS,
      payload: { data: data.result, id },
    });
    if (data.result.length > 0) {
      dispatch(openModal());
    }
  }
};

export const convert = (currentEntity, newEntity, id) => async (dispatch) => {
  dispatch({
    type: actionTypes.LOADING_REQUEST,
    payload: { loading: true },
  });
  let data = {};
  data = await request.put({
    entity: currentEntity,
    action: 'convert/' + endpoints[newEntity].entity,
    id: id,
  });
  if (data.success === true) {
    dispatch({
      type: actionTypes.CONVERSION_SUCCESS,
      payload: data.result,
    });

    if (currentEntity === 'quote' && newEntity === 'order') {
      dispatch(getItemList(data.result.order.id));
    }
  } else {
    dispatch({
      type: actionTypes.FAILED_REQUEST,
      payload: data,
    });
  }
};

export const updatePrice = (id, body) => async (dispatch) => {
  dispatch({
    type: actionTypes.LOADING_REQUEST,
    payload: { loading: true },
  });
  let data = {};
  data = await request.newUpdate({
    entity: 'order',
    action: 'applyPriceChanges',
    id: id,
    jsonData: { items: body },
  });
  if (data.success === true) {
    dispatch({
      type: actionTypes.CHANGE_PRICE_SUCCESS,
      payload: data.result,
    });
  } else {
    dispatch({
      type: actionTypes.FAILED_REQUEST,
      payload: data,
    });
  }
  dispatch(closeModal());
};

export const generatePDF = (id, type) => async (dispatch) => {
  dispatch({
    type: actionTypes.LOADING_REQUEST,
    payload: { loading: true },
  });
  try {
    const data = await request.pdfRequest(id, type);
    const blob = new Blob([data], { type: 'application/pdf' });

    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().substring(0, 10);

    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${formattedDate}-${type}.pdf`);
    document.body.appendChild(link);
    link.click();
    dispatch({
      type: actionTypes.GENERATE_PDF_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.FAILED_REQUEST,
      payload: error,
    });
  }
};

export const getData = (options) => async (dispatch) => {
  try {
    dispatch({
      type: actionTypes.LOADING_REQUEST,
      payload: { loading: true },
    });

    let data = {};
    data = await request.list({
      entity: 'parent',
      options: options,
    });

    if (data.success === true) {
      dispatch({
        type: actionTypes.GET_DATA,
        payload: {
          data: data.result,
          counter: data.pagination.count,
          page: options.page,
          res: data,
        },
      });
      return {
        ...data,
        page: options.page,
        totalPages: data.pagination.totalPages,
      };
    } else {
      dispatch({
        type: actionTypes.FAILED_REQUEST,
        payload: data,
      });
      return {
        ...data,
        page: options.page,
        totalPages: 0,
      };
    }
  } catch (e) {
    dispatch({
      type: actionTypes.FAILED_REQUEST,
      payload: e,
    });
    return e;
  }
};

export const resetItemByID = () => async (dispatch) =>
  dispatch({ type: actionTypes.RESET_ITEM_ID });

export const getDataById = (entity, id) => async (dispatch) => {
  dispatch({ type: actionTypes.LOADING_REQUEST });

  const data = await request.read({
    entity: entity,
    id: id,
  });

  if (data.success === true) {
    dispatch({
      type: actionTypes.GET_DATA_BY_ID,
      payload: data.result,
    });
  } else {
    dispatch({
      type: actionTypes.FAILED_REQUEST,
      payload: data,
    });
  }
};

export const deleteSale = (id, entity) => async (dispatch) => {
  dispatch({
    type: actionTypes.LOADING_REQUEST,
    payload: { loading: true },
  });

  let data = {};
  data = await request.newPost({
    entity: entity,
    action: 'cancel',
    id: id,
  });
  if (data.success === true) {
    dispatch({
      type: actionTypes.DELETE_SUCCESS,
      payload: { entity: endpoints[entity].entity, id },
    });
  } else {
    dispatch({
      type: actionTypes.FAILED_REQUEST,
      payload: data,
    });
  }
};

export const addPayment = (body) => async (dispatch) => {
  dispatch({
    type: actionTypes.LOADING_REQUEST,
    payload: { loading: true },
  });
  let data = {};
  data = await request.create({
    entity: 'payment',
    jsonData: body,
  });
  if (data.success === true) {
    dispatch({
      type: actionTypes.PAYMENT_SUCCESS,
      payload: data.result,
    });
  } else {
    dispatch({
      type: actionTypes.FAILED_REQUEST,
      payload: data,
    });
  }
};
export const deletePayment = (id) => async (dispatch) => {
  dispatch({
    type: actionTypes.LOADING_REQUEST,
    payload: { loading: true },
  });

  let data = {};
  data = await request.delete({
    entity: 'payment',
    id: id,
  });
  if (data.success === true) {
    dispatch({
      type: actionTypes.DELETE_PAYMENT_SUCCESS,
      payload: data.result,
    });
  } else {
    dispatch({
      type: actionTypes.FAILED_REQUEST,
      payload: data,
    });
  }
};
