import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal, Pagination, Select, Table } from 'antd';
import { useErpContext } from '@/context/erp';
import { useDispatch, useSelector } from 'react-redux';
import { erp } from '@/redux/erp/actions';
import { selectCategories, selectItemsByCategory, selectListItems } from '@/redux/erp/selectors';
import TableHeader from '@components/ErpPanel/TableHeader';
import { useTableColumns } from '@components/ErpPanel/useTable';
import {
  buildCategoryTree,
  changeTreeField,
  formatDate,
  formatFloat,
  formatPrice,
} from '@/utils/helpers';
import { DEFAULT_CURRENCY } from '../PriceDisplay';
import { selectCompany } from '@/redux/auth/selectors';
import { useLocation } from 'react-router-dom';

export default function DataTable({ config, isDisabled }) {
  const company = useSelector(selectCompany);
  const [actualEntity, setEntity] = useState(true);
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sorter, setSorter] = useState({});
  const location = useLocation();
  const { columns, setFilters, filters, category, setCategory } = useTableColumns(
    config,
    isDisabled,
    actualEntity,
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const pageSizeOptions = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    // {value: 0, label: "Show all"},
  ];

  const { result: listResult, isLoading: listIsLoading } = useSelector(selectListItems);

  const { pagination, items } = listResult;

  const dispatch = useDispatch();

  //show item when click
  const { erpContextAction } = useErpContext();
  const { readPanel, createPanel } = erpContextAction;

  function Read(id) {
    dispatch(erp.read({ entity: config.entity, id }));
    readPanel.open();
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get('param');
    const saleReturn = params.get('id');

    if (id) {
      Read(id);
    }
    if (saleReturn) {
      createPanel.open();
    }
  }, []);

  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        config.entity != 'role' && Read(record._id);
      },
    };
  };

  const handelDataTableLoad = useCallback(
    (pagination, filters, sorter) => {
      if (sorter && sorter.column && sorter.column.dataIndex && sorter.order) {
        let dataIndex = sorter.column.dataIndex;
        if (dataIndex instanceof Array) {
          dataIndex = dataIndex.join('.');
        }
        setSorter({
          sortBy: dataIndex,
          sortOrder: sorter.order === 'ascend' ? 1 : -1,
        });
      } else {
        setSorter({});
      }
      setFilters(filters);
    },
    [setFilters],
  );

  const handlePaginationChange = useCallback((current) => {
    setPage(current);
  }, []);

  const handlePageSizeChange = useCallback((pageSize) => {
    setPageSize(pageSize);
    setPage(1);
  }, []);

  useEffect(() => {
    let dataTableColumns = actualEntity ? config.dataTableColumns : config.dataTableColumns2;
    const cleanFilters = Object.fromEntries(
      Object.entries(filters)
        .filter(([k, v]) => !!v)
        .map(([k, v]) => {
          const dataType = dataTableColumns.find(({ dataIndex }) => {
            let stringDataIndex = dataIndex;
            if (dataIndex instanceof Array) {
              stringDataIndex = dataIndex.join('.');
            }
            return stringDataIndex === k;
          }).type;
          return [k, { value: v, dataType }];
        }),
    );
    const options = { page: page || 1, limit: pageSize, ...cleanFilters, ...sorter };
    dispatch(
      erp.list({
        entity: actualEntity ? config.entity : config.anotherEntity,
        options,
      }),
    );
  }, [filters, page, sorter, pageSize]);

  useEffect(() => {
    if (!actualEntity) {
      dispatch(erp.listCategories({ entity: config.anotherEntity, options: { limit: 0 } }));
    }
  }, [dispatch, actualEntity, config.entity, config.anotherEntity]);

  useEffect(() => {
    if (category !== null) {
      dispatch(erp.listById({ entity: config.anotherEntity, id: category }));
    }
  }, [category]);

  const { result, isLoading } = useSelector(selectItemsByCategory);

  const { result: listResultCategories, isLoading: loadingCategories } =
    useSelector(selectCategories);

  useEffect(() => {
    if (listResultCategories.items) {
      setCategories(buildCategoryTree(listResultCategories.items));
    }
  }, [listResultCategories.items]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  };

  //this is temporary until further decisions about the actions column be made

  const filteredColumns = columns.filter((column) => typeof column.title === 'string');

  const itemColumns = [
    {
      title: 'NAME',
      dataIndex: 'name',
    },
    {
      title: 'Reference',
      dataIndex: 'reference',
    },
    {
      title: 'STOCK ON HAND',
      dataIndex: 'available_stock',
      type: 'number',
      render: (available_stock) => {
        return formatFloat(available_stock) || '';
      },
    },
    {
      title: 'Selling price HT',
      dataIndex: ['sale', 'price'],
      type: 'number',
      render: (price) => {
        return price ? formatPrice(price, company?.currency || DEFAULT_CURRENCY)?.[0] : '';
      },
    },
    {
      title: 'Purchase price HT',
      dataIndex: ['purchase', 'price'],
      type: 'number',
      render: (price) => {
        return price ? formatPrice(price, company?.currency || DEFAULT_CURRENCY)?.[0] : '';
      },
    },
    {
      title: 'Creation date',
      dataIndex: 'createdAt',
      render: (date) => {
        return formatDate(date);
      },
    },
  ];

  return (
    <>
      <TableHeader
        config={config}
        actualEntity={actualEntity}
        setEntity={setEntity}
        filters={filters}
        setFilters={setFilters}
        selectedItems={selectedRowKeys}
        columns={columns}
      />
      <div className="erp-data-table" style={config.small ? { height: 'calc(100vh - 300px)' } : {}}>
        {(actualEntity ? items : changeTreeField(categories)) && (
          <Table
            rowClassName={() => config.entity === 'document' && 'disabled-row'} // TODO: remove when documents page is fixed
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            // to hide the whole column that has the (+) button just replace the columns here withe the filteredColumns
            columns={columns}
            onRow={onRow}
            rowKey={(item) => item._id}
            dataSource={
              (actualEntity
                ? !listIsLoading
                  ? items
                  : []
                : !loadingCategories
                  ? changeTreeField(categories)
                  : []) ?? []
            }
            pagination={false}
            loading={actualEntity ? listIsLoading : loadingCategories}
            onChange={handelDataTableLoad}
            tableLayout="auto"
            scroll={{ x: '1000px' }}
            virtual
          />
        )}
        {config.anotherEntity ? (
          <Modal
            width={900}
            title={'Show Items By Category'}
            visible={category !== null}
            onCancel={() => {
              setCategory(null);
            }}
            footer={[
              <Button
                key="close"
                onClick={() => {
                  setCategory(null);
                }}
              >
                Cancel
              </Button>,
            ]}
          >
            <div>
              <Table
                scroll={{ y: 'calc(100vh - 360px)' }}
                pagination={false}
                columns={itemColumns}
                dataSource={result}
                loading={isLoading}
              />
            </div>
          </Modal>
        ) : null}
        <div className="pagination-wrapper">
          <div>
            <Pagination
              onChange={handlePaginationChange}
              current={pagination.current}
              pageSize={pagination.pageSize}
              total={pagination.total}
              showSizeChanger={false}
            ></Pagination>
          </div>
          <div>
            <Select
              onChange={handlePageSizeChange}
              options={pageSizeOptions}
              value={pagination.pageSize}
              // defaultValue={10}
              style={{ width: 120 }}
            ></Select>
          </div>
        </div>
      </div>
    </>
  );
}
