import { Button, Dropdown, Menu, PageHeader, Popconfirm, Segmented } from 'antd';
import dots_horizontal from '@/style/images/dots_horizontal.svg';
import {
  AppstoreOutlined,
  ArrowLeftOutlined,
  BarsOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { useEffect } from 'react';
import { useErpContext } from '@/context/erp';
import { useDispatch, useSelector } from 'react-redux';
import { erp } from '@/redux/erp/actions';
import { selectDeletedManyItems } from '@/redux/erp/selectors';
import { createRole, deleteRole } from '@/utils/role';
import { selectRole } from '@/redux/auth/selectors';
import useTranslations from '@/hooks/useTranslation';

function AddNewItem({ config }) {
  const { ADD_NEW_ENTITY } = config;
  const { erpContextAction } = useErpContext();
  const { createPanel } = erpContextAction;
  const role = useSelector(selectRole);
  const handelClick = () => {
    createPanel.open();
  };

  return (
    <Button style={{ background: '#326CFE' }} className="add-item-button" onClick={handelClick}>
      {ADD_NEW_ENTITY}
    </Button>
  );
}

export default function TableHeader({
  config,
  filters,
  setFilters,
  selectedItems,
  actualEntity,
  setEntity,
  columns,
}) {
  const { t } = useTranslations('common');
  const { DATATABLE_TITLE } = config;
  const dispatch = useDispatch();
  const { isSuccess } = useSelector(selectDeletedManyItems);
  const role = useSelector(selectRole);

  useEffect(() => {
    if (isSuccess) {
      dispatch(erp.list({ entity: config.entity }));
    }
  }, [isSuccess]);
  const deleteMany = () => {
    dispatch(erp.deleteMany({ entity: config.entity, ids: selectedItems }));
  };

  return (
    <>
      <PageHeader
        className={'header'}
        backIcon={<ArrowLeftOutlined />}
        onBack={() => window.history.back()}
        title={DATATABLE_TITLE}
        ghost={true}
        extra={[
          (createRole(role, config.entity) && config.ADD_NEW_ENTITY) || config.entity == 'role' ? (
            <AddNewItem key={1} config={config} />
          ) : null,
          <div key={'2'}>
            {config.anotherEntity ? (
              <Segmented
                style={{ marginRight: 10 }}
                options={[
                  {
                    label: `${t('inventory.item.form.item', true)}`,
                    value: true,
                    icon: <BarsOutlined />,
                  },
                  {
                    label: `${t('inventory.item.category', true)}`,
                    value: false,
                    icon: <AppstoreOutlined />,
                  },
                ]}
                onChange={(value) => {
                  setEntity(value);
                }}
              />
            ) : null}
          </div>,
          <Dropdown
            key={3}
            overlay={() => (
              <Menu>
                {deleteRole(role, config.entity) && (config.delete ?? true) ? (
                  selectedItems.length > 0 ? (
                    <Popconfirm
                      title={`Are you sure to delete these ${selectedItems.length} ${config.entity}s ?`} //We should agree on what should be shown here
                      onConfirm={deleteMany}
                      okText={t('yes')}
                      cancelText={t('no')}
                    >
                      <Menu.Item key={0}>{t('bulkDelete')}</Menu.Item>
                    </Popconfirm>
                  ) : (
                    <Popconfirm
                      title={t('itemsDelete')}
                      okText={t('confirm')}
                      cancelText={t('cancel')}
                    >
                      <Menu.Item key={0}>{t('bulkDelete')}</Menu.Item>
                    </Popconfirm>
                  )
                ) : null}
              </Menu>
            )}
          >
            <img className="cursor-pointer" src={dots_horizontal} alt="#" />
          </Dropdown>,
        ]}
      />
      <div className={'filters-container'}>
        <Filters filters={filters} setFilters={setFilters} columns={columns} />
      </div>
    </>
  );
}

const Filters = ({ filters, setFilters, columns }) => {
  const { t } = useTranslations('common');
  const filtersValues = Object.values(filters ?? {});
  const filtersKeys = Object.keys(filters ?? {});

  return filtersValues.every((e) => e === null) ? null : (
    <div className={'filters'}>
      <span className={'title'}> {t('appliedFilters')}: </span>
      {filtersKeys.map((item) => {
        return (
          <FilterItem
            key={item}
            filters={filters}
            item={item}
            setFilters={setFilters}
            columns={columns}
          />
        );
      })}
    </div>
  );
};

const FilterItem = ({ filters, item, setFilters, columns }) => {
  const removeFilter = () => {
    let params = { ...filters };
    params[item] = null;
    setFilters(params);
  };

  const column = columns.find(({ dataIndex }) => {
    const dataIndexString = Array.isArray(dataIndex) ? dataIndex.join('.') : dataIndex;
    return dataIndexString === item;
  });
  const title = column?.title || item;
  return filters[item] ? (
    <span key={filters[item]} className={'filter-item'}>
      <span style={{ fontWeight: 700 }}>{title + ' : '}</span>
      {filters[item].map((e) => ` ${!e && e !== 0 ? t('emptyValue') : e} `)}
      <CloseOutlined style={{ width: 20, height: 14 }} onClick={removeFilter} />
    </span>
  ) : null;
};
