export const API_BASE_URL =
  process.env.REACT_APP_ENV === 'LOCAL_DEV'
    ? 'http://localhost:8888/'
    : process.env.REACT_APP_ENV === 'DEV'
    ? 'https://gateway-dev.supplyz.tech/'
    : 'https://gateway-prod.supplyz.tech/';

export default API_BASE_URL;

export const ACCESS_TOKEN_NAME = 'x-auth-token';
